function scrollTrigger(selector, options = {}) {
    let elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
        let el = elements[i];
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('actived');
                    observer.unobserve(entry.target);
                }
            });
        }, options);
        observer.observe(el);
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const minus50 = {
        rootMargin: '-50px'
    };
    scrollTrigger('.fade-in', minus50);
    scrollTrigger('.fade-left', minus50);
    scrollTrigger('.fade-right', minus50);
    scrollTrigger('.fade-top', minus50);
    scrollTrigger('.reveal', minus50);
});